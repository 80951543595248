<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedPdfActions"
        :default-value="properties.action"
        step-label="pdf"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-if="properties.action === 'GET_PAGE_COUNT'
          || properties.action === 'GET_PAGE_AS_PICTURE'
          || properties.action === 'GET_TEXT'"
        :key="`${step.id}-sourceObject`"
        v-model="properties.fields.sourceObject"
        outlined
        dense
        :label="$lang.labels.sourceObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>

    <v-col v-if="properties.action === 'GET_PAGE_AS_PICTURE'" cols="12">
      <v-text-field
        :key="`${step.id}-pageNumber`"
        v-model="properties.fields.pageNumber"
        outlined
        dense
        :label="$lang.labels.pageNumber"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
      <v-select
        v-model="selectedQuality"
        :items="formattedImageQualities"
        outlined
        dense
        :label="$lang.labels.imageQuality"
        data-cy="pdf-image-quality"
        @change="handleChange('fields.scale', $event)"
      />
    </v-col>

    <text-templates-select
      v-if="properties.action === 'GENERATE'"
      :key="`${step.id}-textTemplate`"
      step-type="PDF"
      :properties="properties"
      step-label="pdf"
      :readonly="!canEdit"
      :required="false"
      :default-value="properties.fields.textTemplate"
      @change="handleChange('fields.textTemplate', $event)"
    />

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        data-cy="pdf-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        class="required-asterisk"
      />
    </v-col>

    <v-col cols="12">
      <locales-select
        v-if="properties.action === 'GENERATE'"
        :key="`${step.id}-locale`"
        :properties="properties"
        step-label="pdf"
        :readonly="!canEdit"
        :required="properties.action === 'GENERATE'"
        :rules="properties.action === 'GENERATE' ? [v => !!v || $lang.labels.required] : []"
        :class="properties.action === 'GENERATE' ? 'required-asterisk' : ''"
        :default-value="properties.fields.locale"
        @change="handleChange('fields.locale', $event)"
      />
    </v-col>

    <v-col cols="12" class="pb-3">
      <add-key-value
        v-if="properties.action === 'GENERATE'"
        :key="`${step.id}-textTemplate`"
        type="variables"
        :can-edit="canEdit"
        step-type="PDF"
        :data="properties.fields.variables"
        :text-template-name="properties.fields.textTemplate"
        :single-step="step"
        @dataChanged="handleChange('fields.variables', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { PDF_ACTIONS, IMAGE_QUALITIES } from './constants'
import { TextTemplatesSelect, LocalesSelect, ActionSelect } from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'
import { getNestedPropertyForSetting, castValueForStep } from '@/utils/helpers'

export default {
  name: 'PdfStep',
  components: {
    TextTemplatesSelect,
    LocalesSelect,
    AddKeyValue,
    ActionSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      selectedQuality: '1'
    }
  },
  computed: {
    formattedPdfActions() {
      return PDF_ACTIONS.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    },
    formattedImageQualities() {
      return Object.entries(IMAGE_QUALITIES).map(([key, value]) => ({
        text: this.$lang.labels[key],
        value: value
      }))
    }
  },
  created() {
    if (!this.properties.fields) this.$set(this.properties, 'fields', {})
    if (!this.properties.fields.variables) this.$set(this.properties.fields, 'variables', {})
    if (this.properties.fields.scale) this.selectedQuality = this.properties.fields.scale
  },
  methods: {
    handleChange(key, val) {
      // Typecasting for specific fields
      const value = castValueForStep(
        key,
        val,
        {
          shouldBeString: ['fields.textTemplate'],
          shouldBeMapOfStrings: ['fields.variables']
        }
      )

      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, value)

        return
      }

      this.$set(this.properties, key, value)
    }
  }
}
</script>
